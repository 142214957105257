import { Box, OSKThemeType, Text } from 'oskcomponents';
import { Capture, DownloadIntent, noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from '~/redux/store';
import { CartWizardStep, CartWizardTemplate } from '~/templates';
import CartCollectPreview from '~/organisms/CartCollectPreview';

import { getAllItems, getFullItemsByCollect } from '~/redux/modules/data/cart';

export type CheckoutSummaryProps = {
    intents?: DownloadIntent[];
    cartItems?: Record<string, Capture[]>;
} & CartWizardStep;

export const CheckoutSummary = ({ intents, onWizardNext, onWizardClose }: CheckoutSummaryProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const totalImagery = (intents ?? []).filter((intent) => !intent.skip).length;

    return (
        <CartWizardTemplate
            buttons={[
                {
                    variant: 'primary',
                    inverted: true,
                    label: 'Close',
                    onClick: () => {
                        onWizardClose && onWizardClose();
                    },
                },
                {
                    variant: 'action',
                    noborder: true,
                    label: 'Next',
                    onClick: onWizardNext ?? noop,
                },
            ]}
            style={{
                overflowY: 'hidden',
            }}
        >
            <Text variant="small">
                Total Images: <span style={{ fontWeight: 'bold' }}>{totalImagery}</span>
            </Text>
            <Box
                fg={theme.colors.black900}
                col
                style={{ overflowY: 'auto', height: '100%', alignItems: 'center', width: '100%' }}
            >
                <Text pt={14}>Please review your cart below.</Text>
                <Box grow style={{ maxWidth: '650px', width: '50%', paddingTop: '20px', paddingBottom: '20px' }} col>
                    <CartCollectPreview />
                </Box>
            </Box>
        </CartWizardTemplate>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        intents: getAllItems(state),
        cartItems: getFullItemsByCollect(state),
        footprints: state.data.map.footprints,
    };
};

export default connect(mapStateToProps, noop)(CheckoutSummary);
