import React from 'react';
import PromptWhenLeaving from '../PromptWhenLeaving';
import { connect, useDispatch } from 'react-redux';
import { noop } from 'oskcore';
import { CartWizardFlow, emptyCart, getVisibleItems, setWizardFlow } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';

type PromptWhenLeavingHomeViewProps = {
    /** From redux, the # of items in the cart. */
    cartLength: number;
    /** The current state of the checkout wizard */
    wizardFlow: CartWizardFlow;
};

const PromptWhenLeavingHomeView = ({ cartLength, wizardFlow }: PromptWhenLeavingHomeViewProps) => {
    const dispatch = useDispatch();
    const title = `Clear Cart${wizardFlow !== 'none' ? ' and Exit Wizard' : ''}?`;
    const message = `Your cart will be emptied${
        wizardFlow !== 'none' ? ' and the checkout wizard will be closed' : ''
    }. Do you want to proceed?`;
    return (
        <PromptWhenLeaving
            shouldBlock={() => cartLength > 0}
            onProceed={() => {
                dispatch(emptyCart());
                dispatch(setWizardFlow('none')); // Make sure the wizard is closed
            }}
            title={title}
            body={message}
            cancelLabel="Cancel"
            proceedLabel="Clear Cart and Proceed"
        />
    );
};

function mapStateToProps(state: RootState) {
    return {
        cartLength: getVisibleItems(state).length,
        wizardFlow: state.data.cart.wizardFlow,
    };
}

export default connect(mapStateToProps, noop)(PromptWhenLeavingHomeView);
