import React, { useCallback, useEffect, useRef, useState } from 'react';
import { noop, OSKGeoJson } from 'oskcore';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, DatePicker, OSKThemeType, Slider, Typography } from 'oskcomponents';
import { useDisableFeatureOnMouseOver, useMap } from '~/hooks';
import { connect, useDispatch } from 'react-redux';
import { GlobalZIndex } from 'oskcomponents/src/constants';
import GeocodingSearch from '../GeocodingSearch';
import { useTheme } from 'styled-components';
import { ListEntry } from 'oskcomponents';
import { Sensor } from 'oskcore';
import { RootState } from '../../redux/store';
import { DropdownFilterPanel } from './DropdownFilterPanel';
import { FilterPanelButton } from './FilterPanelButton';
import {
    excludePlatform,
    includePlatform,
    setDateRange,
    NullableDate,
    setIsSearchDirty,
    setCloudCoverFilter,
    doSearchAsyncForTaskId,
} from '~/redux/modules/data/search';

import { LatLng } from 'leaflet';
import * as L from 'leaflet';
import { date_format, ensureUUIDFormat } from '~/utils';
import { useToggles } from '~/hooks/useToggles';
import { FaCloud, FaSun } from 'react-icons/fa';

type SearchTopBarProps = {
    /** Available Sensor options */
    sensors?: Sensor[];
    /** List of sensors that are currently selected (for filtering) */
    selectedSensors?: Array<number>;
};

const SearchTopBar = ({ sensors, selectedSensors }: SearchTopBarProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toggles = useToggles();
    const map = useMap();
    const theme = useTheme() as OSKThemeType;
    const ref = useRef<HTMLDivElement>(null);

    const sensorOptions: ListEntry[] = (sensors ?? []).map((sensor) => {
        const { osk_id, osk_sensor_name } = sensor;
        return { label: osk_sensor_name, value: osk_id };
    });

    const [searchStartDate, setSearchStartDate] = useState<NullableDate>(null);
    const [searchEndDate, setSearchEndDate] = useState<NullableDate>(new Date());
    const [dateError, setDateError] = useState<string>('');
    const [cacheSelectedSensors, setCacheSelectedSensors] = useState<number[]>(selectedSensors ?? []);
    const [cloudCover, setCloudCover] = useState<number>(100);

    useDisableFeatureOnMouseOver(ref, 'Drag', true);
    useDisableFeatureOnMouseOver(ref, 'Zoom', true);

    useEffect(() => {
        // Because it's the end date, we want to capture all tasks
        // occuring *on* the day itself. (Add 24 hours.)
        const dayMs = 1000 * 60 * 60 * 24;
        const adjustedEndDate = new Date((searchEndDate?.getTime() ?? 0) + dayMs);

        dispatch(setDateRange(searchStartDate, adjustedEndDate));
    }, [searchStartDate, dispatch, searchEndDate]);

    useEffect(() => {
        sensorOptions.map((platform) => dispatch(excludePlatform(platform.value)));
        cacheSelectedSensors.map((platform) => dispatch(includePlatform(platform)));
    }, [cacheSelectedSensors]);

    useEffect(() => {
        dispatch(setCloudCoverFilter(cloudCover));
    }, [cloudCover]);

    const ValidateDateRange = (start: NullableDate, end: NullableDate): boolean => {
        let dateError = '';
        if ((start && searchEndDate && start > searchEndDate) || (end && searchStartDate && end < searchStartDate)) {
            dateError = 'Start date cannot be after end date.';
        }

        setDateError(dateError);

        if (dateError) return false;

        return true;
    };

    const resetDateFilters = useCallback(() => {
        setSearchStartDate(null);
        setSearchEndDate(new Date());
        dispatch(setIsSearchDirty(true));
    }, [setSearchStartDate, setSearchEndDate]);

    const resetSensorFilters = useCallback(() => {
        setCacheSelectedSensors(sensors?.map((sensor) => sensor.osk_id) ?? []);
        dispatch(setIsSearchDirty(true));
    }, [setCacheSelectedSensors, sensors]);

    const resetCloudCoverFilters = useCallback(() => {
        setCloudCover(100);
        dispatch(setIsSearchDirty(true));
    }, [setCloudCover, cloudCover]);

    return (
        <Box style={{ position: 'relative', pointerEvents: 'auto' }} ref={ref} id="search-top-bar">
            <Box
                style={{
                    width: '100%',
                    height: '50px',
                    zIndex: GlobalZIndex.Overlay + 1,
                    backgroundColor: 'white',
                    padding: '0px 18px',
                }}
                center="vertical"
            >
                <Box style={{ height: 'fit-content', width: '100%' }} row>
                    <GeocodingSearch
                        style={{ width: '315px' }}
                        placeholder="Navigate to GPS, location name, or task id"
                        onSelected={(location, bounds) => {
                            const url = `/map/@${location.lat},${location.lng}`;
                            navigate(url, { replace: true });

                            if (bounds) {
                                // If we have a boundary, let's fit the whole thing
                                const latLngBounds = L.latLngBounds(bounds.southwest, bounds.northeast);
                                map.fitCoordinates([OSKGeoJson.fromLatLngBounds(latLngBounds)], 0.1);
                            } else {
                                // Otherwise, if we just have a point that's ok too.
                                const coord = { lat: location.lat, lng: location.lng } as LatLng;
                                map.fitCoordinates([OSKGeoJson.fromCoordinate(coord)], 0.1);
                            }
                        }}
                        onQueryChanged={(query) => {
                            const cleanQuery = ensureUUIDFormat(query);

                            // Best guess if it's a UUID
                            if (cleanQuery.length === 36 && cleanQuery.includes('-')) {
                                dispatch<any>(doSearchAsyncForTaskId(cleanQuery, false));
                            }
                        }}
                    />
                    <Box
                        style={{
                            backgroundColor: theme.colors.black200,
                            minWidth: '1px',
                            maxWidth: '1px',
                            margin: '0px 10px 0px 16px',
                        }}
                        grow
                    />
                    <Box style={{ justifyContent: 'space-between' }} grow>
                        <Box>
                            <FilterPanelButton
                                panel={
                                    <DropdownFilterPanel
                                        title="Date"
                                        description="Select the date range to search within."
                                        onResetFilter={() => resetDateFilters()}
                                    >
                                        <Box style={{ justifyContent: 'space-between', paddingTop: '8px' }}>
                                            <DatePicker
                                                variant="contrast"
                                                name="start-date"
                                                defaultValue={searchStartDate}
                                                style={{ marginRight: '2px' }}
                                                validate={(date) => {
                                                    return ValidateDateRange(date, null);
                                                }}
                                                onChange={(newDate: NullableDate) => {
                                                    setSearchStartDate(newDate);
                                                    if (ValidateDateRange(newDate, null)) {
                                                        dispatch(setIsSearchDirty(true));
                                                    } else {
                                                        setSearchStartDate(null);
                                                    }
                                                }}
                                            />
                                            <DatePicker
                                                variant="contrast"
                                                name="end-date"
                                                defaultValue={searchEndDate}
                                                validate={(date) => {
                                                    return ValidateDateRange(null, date);
                                                }}
                                                onChange={(newDate: NullableDate) => {
                                                    setSearchEndDate(newDate);
                                                    if (ValidateDateRange(null, newDate)) {
                                                        dispatch(setIsSearchDirty(true));
                                                    } else {
                                                        setSearchEndDate(null);
                                                    }
                                                }}
                                            />
                                        </Box>
                                        {dateError && (
                                            <Typography variant="body3" color="red" vm={8}>
                                                {dateError}
                                            </Typography>
                                        )}
                                    </DropdownFilterPanel>
                                }
                                showClear={searchStartDate !== null && searchEndDate !== null}
                                clearFilter={() => resetDateFilters()}
                                icon="calendar"
                            >
                                <Typography variant="caption1" style={{ minWidth: '130px' }}>
                                    {searchStartDate || searchEndDate
                                        ? (() => {
                                              const from = date_format(searchStartDate);
                                              const to = date_format(searchEndDate);
                                              return `${from ? from : 'All'} - ${to ? to : 'Present'}`;
                                          })()
                                        : 'Date Range'}
                                </Typography>
                            </FilterPanelButton>
                            <FilterPanelButton
                                panel={
                                    <DropdownFilterPanel
                                        title="Satellites"
                                        description="Select which satellites to search."
                                        onResetFilter={() => resetSensorFilters()}
                                    >
                                        {sensorOptions.map((sensor) => (
                                            <Checkbox
                                                scale={150}
                                                key={`sensor-${sensor.value}`}
                                                defaultChecked={cacheSelectedSensors.includes(sensor.value)}
                                                onChange={(e) => {
                                                    dispatch(setIsSearchDirty(true));

                                                    if (cacheSelectedSensors.includes(sensor.value)) {
                                                        setCacheSelectedSensors(
                                                            cacheSelectedSensors.filter((id) => id !== sensor.value),
                                                        );
                                                    } else {
                                                        setCacheSelectedSensors([
                                                            ...cacheSelectedSensors,
                                                            sensor.value,
                                                        ]);
                                                    }
                                                }}
                                            >
                                                <Typography variant="body2" style={{ padding: '4px 8px' }}>
                                                    {sensor.label}
                                                </Typography>
                                            </Checkbox>
                                        ))}
                                    </DropdownFilterPanel>
                                }
                                icon="satellite"
                            >
                                <Box center="vertical" style={{ minWidth: '75px' }}>
                                    <Typography variant="caption1">Satellites</Typography>
                                    {cacheSelectedSensors && cacheSelectedSensors?.length > 0 && (
                                        <Box
                                            style={{
                                                padding: '4px',
                                                backgroundColor: theme.colors.accent,
                                                color: 'white',
                                                borderRadius: '100px',
                                                width: '18px',
                                                height: '18px',
                                                marginLeft: '8px',
                                                marginRight: '-4px',
                                            }}
                                            center="all"
                                        >
                                            <Typography variant="caption3">{cacheSelectedSensors?.length}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </FilterPanelButton>
                            <FilterPanelButton
                                panel={
                                    <DropdownFilterPanel
                                        title="Cloud Coverage"
                                        description="Use the slider to specify the acceptable amount of cloud coverage."
                                        onResetFilter={() => resetCloudCoverFilters()}
                                    >
                                        <Box
                                            center="all"
                                            style={{
                                                width: '100%',
                                                minWidth: '300px',
                                                padding: '0px 0px 14px 0px',
                                            }}
                                        >
                                            <Slider
                                                defaultValue={cloudCover}
                                                minValue={0}
                                                maxValue={100}
                                                decimals={0}
                                                getLabel={(value) => `${value}%`}
                                                minValueLabel={
                                                    <Box col center="horizontal" w={20}>
                                                        <span style={{ margin: '2px 0px' }}>0%</span>
                                                        <FaSun />
                                                    </Box>
                                                }
                                                maxValueLabel={
                                                    <Box col center="horizontal" w={20}>
                                                        <span style={{ margin: '2px 0px' }}>100%</span>
                                                        <FaCloud />
                                                    </Box>
                                                }
                                                variant="action"
                                                onChange={(newValue) => {
                                                    dispatch(setIsSearchDirty(true));
                                                    setCloudCover(newValue);
                                                }}
                                            />
                                        </Box>
                                    </DropdownFilterPanel>
                                }
                                icon="cloud"
                            >
                                <Box style={{ minWidth: '130px' }} center="vertical">
                                    <Typography variant="caption1">Cloud Coverage</Typography>
                                    <Box
                                        style={{
                                            padding: '4px',
                                            backgroundColor: theme.colors.accent,
                                            color: 'white',
                                            borderRadius: '100px',
                                            height: '18px',
                                            marginLeft: '8px',
                                            marginRight: '-4px',
                                        }}
                                        center="all"
                                    >
                                        <Typography variant="caption3">
                                            {cloudCover ? <>&lt; {cloudCover}%</> : 'None'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </FilterPanelButton>
                        </Box>
                        <Box>
                            <Button
                                variant="contrast"
                                style={{ alignSelf: 'flex-end', height: '36px', minWidth: '115px' }}
                                onClick={() => {
                                    resetDateFilters();
                                    resetSensorFilters();
                                    resetCloudCoverFilters();
                                }}
                            >
                                <Typography variant="caption1">Reset Filters</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const accessibleSensors = state.osk.sensors.filter((sensor) => state.osk.accessibleSensors.includes(sensor.osk_id));
    const selectedSensors = state.data.search.platforms.filter((platform) =>
        state.osk.accessibleSensors.includes(platform),
    );
    return {
        sensors: accessibleSensors,
        selectedSensors,
    };
};

export default connect(mapStateToProps, noop)(SearchTopBar);
